import React from "react"
import Layout from "../layout/GeneralLayout"
import {
  Box,
  Heading,
  Paragraph,
  GlassBox,
  Anchor,
} from "../components/commonElements"
import styled from "styled-components"
import Footer from "../components/footer"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { latestUpdatesData } from "../data/latest-updates-data"

const Container = styled(GlassBox)`
  font-size: 1.8rem;
  max-width: ${props => `calc(${props.theme.containerSize} - 2rem)`};
  align-items: center;
  min-height: 78vh;
  width: calc(100% - 2rem);
  @media ${props => props.theme.device.tablet} {
    min-height: 83vh;
  }
`

const Table = styled(Box)`
  flex-direction: column;
  width: 80rem;
  margin-top: 2rem;
`

const Row = styled(Box)`
  flex-direction: row;
  margin-bottom: 1.45rem;
  @media screen and (max-width: 850px) {
    flex-direction: column;
    &:nth-child(1) {
      display: none;
    }
  }
`

const Column1 = styled(Box)`
  flex-direction: column;
  width: 12rem;
  flex: 0 0 auto;
  @media screen and (max-width: 850px) {
    font-weight: bold;
    width: 100%;
  }
`

const Column2 = styled(Box)`
  flex-direction: column;
  width: 18rem;
  flex: 0 0 auto;
  text-align: center;
  @media screen and (max-width: 850px) {
    text-align: left;
    width: 100%;
  }
`

const Column3 = styled(Box)`
  flex-direction: column;
  width: 50rem;
  flex: 0 0 auto;
  @media screen and (max-width: 850px) {
    width: 100%;
  }
`

const RowSeparator = styled.hr`
  background-color: transparent;
  border-top: 2px solid rgb(205, 205, 205);
  margin: 0.5rem 0rem;
`

const ColumnHeading = styled.h5`
  text-align: ${props => props.textAlign};
  margin-bottom: 0;
`

const ColumnText = styled.p`
  /* font-size: 1.6rem; */
  margin-bottom: 0;
  box-sizing: inherit;
`

const ColumnTextSmall = styled.p`
  font-size: 1.3rem;
  margin-bottom: 0;
  color: ${props => props.color};
  font-weight: ${props => props.fontWeight};
`

const GatsbyIcon = styled(GatsbyImage)`
  margin-top: 5px;
`

const InnerRow = styled(Box)`
  width: 45rem;
  flex-direction: row;
  margin-bottom: 1.45rem;
  @media screen and (max-width: 850px) {
    width: 90%;
  }
`

const InnerColumn = styled(Box)`
  flex-direction: column;
  flex: 0 0 auto;
  margin-right: 1rem;
  & > img {
    width: 15px !important;
    height: auto !important;
  }
`

const query = graphql`
  query {
    CoinImage: file(relativePath: { eq: "tools/coin-image.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 17
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    DiceImage: file(relativePath: { eq: "tools/dice-image.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 17
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    TarotImage: file(relativePath: { eq: "tools/tarot-image.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 18
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    Coin2048Image: file(relativePath: { eq: "tools/2048-image.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 15
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    FlipSimuImage: file(relativePath: { eq: "LogoGaming.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 18
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    Station2048Image: file(
      relativePath: { eq: "tools/2048station-image.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 15
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    NumberImage: file(relativePath: { eq: "tools/number-image.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 18
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
  }
`

const iconArray = [
  {
    url: "/",
    text: "Coin Flip Simulator",
    alt: "coin flipper",
    image: "CoinImage",
  },
  {
    url: "/dice-roller/",
    text: "Dice Roller",
    alt: "dice roller",
    image: "DiceImage",
  },
  {
    url: "/yes-or-no-tarot/",
    text: "Yes No Tarot",
    alt: "yes or no tarot",
    image: "TarotImage",
  },
  {
    url: "/2048-coin/",
    text: "FS 2048",
    alt: "fs 2048",
    image: "Coin2048Image",
  },
  {
    url: "/",
    text: "FS",
    alt: "fs",
    image: "FlipSimuImage",
  },
  {
    url: "https://2048.guru/",
    text: "2048 Guru",
    alt: "2048 Guru",
    image: "Station2048Image",
    external: true,
  },
  {
    url: "/number-cards/",
    text: "Number Flashcards",
    alt: "number flashcards",
    image: "NumberImage",
  },
]

const ToolIcon = ({ image }) => {
  const iconData = iconArray.filter(icon => icon.image === image)
  const data = useStaticQuery(query)
  const imageSrc = getImage(data[image])
  const external = iconData[0].external ? iconData[0].external : false
  return (
    <>
      {!external ? (
        <Anchor href={iconData[0].url} alt={iconData[0].alt}>
          <GatsbyIcon
            image={imageSrc}
            alt={iconData[0].alt}
            title={iconData[0].text}
            external={iconData[0].external}
          />
        </Anchor>
      ) : (
        <Anchor href={iconData[0].url} alt={iconData[0].alt} target="_blank">
          <GatsbyIcon
            image={imageSrc}
            alt={iconData[0].alt}
            title={iconData[0].text}
            external={iconData[0].external}
          />
        </Anchor>
      )}
    </>
  )
}

const latestupdates = () => {
  const initialVersion = 30

  return (
    <Layout>
      <Box flex="1" mt="1rem" alignItems="center" gap="1rem">
        <Container backgroundColor="white" padding="2rem">
          <Heading level={1} textAlign="center">
            Latest Updates
          </Heading>
          <Paragraph level={4} textAlign="center">
            (This page will only log about the new and improved features. All
            the bugs fixed will not be reported.)
          </Paragraph>

          <Table>
            <Row>
              <Column1>
                <ColumnHeading textAlign="center">Date</ColumnHeading>
              </Column1>
              <Column2>
                <ColumnHeading>Version</ColumnHeading>
              </Column2>
              <Column3>
                <ColumnHeading textAlign="center">Description</ColumnHeading>
              </Column3>
            </Row>
            <RowSeparator />

            {latestUpdatesData.map((item, index) => {
              return (
                <>
                  <Row>
                    <Column1>
                      <ColumnText>{item.date}</ColumnText>
                    </Column1>
                    <Column2>
                      <ColumnText>
                        Version{" "}
                        {initialVersion + latestUpdatesData.length - index}
                      </ColumnText>
                      <ColumnTextSmall color="#58559d">
                        {item.info}
                      </ColumnTextSmall>
                    </Column2>
                    <Column3>
                      {item.description.map((descItem, descIndex) => {
                        return (
                          <InnerRow>
                            <InnerColumn>
                              {descItem.icon.map((icon, iconIndex) => {
                                return <ToolIcon image={icon} />
                              })}
                            </InnerColumn>
                            <InnerColumn>
                              <ColumnText>{descItem.title}</ColumnText>
                              <ColumnTextSmall>
                                {descItem.detail}
                              </ColumnTextSmall>
                            </InnerColumn>
                          </InnerRow>
                        )
                      })}
                    </Column3>
                  </Row>
                  <RowSeparator />
                </>
              )
            })}
          </Table>
        </Container>
        <Footer />
      </Box>
    </Layout>
  )
}

export default latestupdates

export const Head = () => (
  <SEO
    titleP="Latest Updates | FlipSimu"
    descriptionP="This is the latest updates page of FlipSimu"
    pathname="/latest-updates/"
  />
)
